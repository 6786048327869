import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-37b7d506"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "title-box"
};
const _hoisted_2 = {
  class: "primary_color"
};
const _hoisted_3 = {
  key: 0
};
const _hoisted_4 = {
  class: "opt-box"
};
const _hoisted_5 = {
  class: "btn-box"
};
const _hoisted_6 = {
  class: "btn-box"
};
const _hoisted_7 = {
  key: 0,
  class: "btn-box"
};
import { reactive, ref, computed } from 'vue';
import { Delete, Plus, Select, Edit } from '@element-plus/icons-vue'; // import { AllDict } from '@/utils/common'

import { // editGoods,
// addGoods,
// delImgItem,
// getActivities,
getGoodsSorts, updateGoodsSorts, addGoodsSorts, deleteGoodsSorts } from '@/api/goods';
import { ElMessage } from 'element-plus';
import ImageUpload from '@/components/ImageUpload';
const formLabelWidth = '80px'; // const emit = defineEmits(['refresh'])

export default {
  __name: 'modal-sorts-setting',

  setup(__props, {
    expose: __expose
  }) {
    const dialogVisible = ref(false);
    const formRef = ref();
    const rules = ref({
      subject: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      specification: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }],
      unitPrice: [{
        required: true,
        message: '请输入',
        trigger: 'blur'
      }]
    });

    const generateFrom = () => {
      return {
        goodsId: '',
        goodAllSorts: [{
          goodsId: null,
          // 商品Id号
          sortPhotoUrls: null,
          // 1个图片链接,图片比例为1:1
          sortPrice: null,
          // 型号单价
          sortName: null,
          // 型号名称，不允许有emoji表情
          sortStocks: null,
          // 型号库存量
          sortId: null,
          // 型号ID
          isEdit: false
        }]
      };
    };

    const formModel = reactive(generateFrom());

    const showModal = data => {
      if (!data.goodsId) {
        Object.assign(formModel, generateFrom());
      } else {
        Object.assign(formModel, data);
      }

      getGoodsSortsFn();
      dialogVisible.value = true;
    }; // const hideModal = () => {
    //   dialogVisible.value = false
    // }


    const title = computed(() => {
      return `${formModel.goodsId ? '编辑' : '新建'}商品型号 ${formModel.goodsId ? '商品ID-' + formModel.goodsId : ''}`;
    }); // 删除型号

    const deleteGoodsSortsFn = async item => {
      console.log('确认删除型号');
      console.log(item);

      try {
        const newitem = {
          images: item.sortPhotoUrls ? item.sortPhotoUrls.split(',') : '',
          sortId: item.sortId
        };
        const resData = await deleteGoodsSorts(newitem);

        if (resData && resData.status === 'success') {
          ElMessage.success('删除成功');
          getGoodsSortsFn();
        }
      } catch (err) {
        console.log(err); // hideModal()
      } // deleteGoodsSorts({
      //   // images: [].concat(imgArr),
      //   images: item.sortPhotoUrls.split(','),
      //   sortId: item.sortId
      // }).then((res) => {
      //   if (res.status === 'success') {
      //     ElMessage.success('删除成功')
      //     getGoodsSortsFn()
      //   }
      // })

    };

    const newGoodsSortsFn = () => {
      formModel.goodAllSorts.push({
        goodsId: formModel.goodsId,
        // 商品Id号
        sortPhotoUrls: null,
        // 1个图片链接,图片比例为1:1
        sortPrice: null,
        // 型号单价
        sortName: null,
        // 型号名称，不允许有emoji表情
        sortStocks: null,
        // 型号库存量
        isEdit: true
      });
    };

    const openEditFn = item => {
      item.isEdit = true;
    }; // 新建商品型号


    const addGoodsSortsFn = async item => {
      try {
        var newitem = item;
        delete newitem.isEdit;
        const resData = await addGoodsSorts(newitem);

        if (resData && resData.status === 'success') {
          ElMessage({
            message: '新建成功！',
            type: 'success'
          });
          item.isEdit = false;
          getGoodsSortsFn();
        }
      } catch (err) {
        console.log(err); // hideModal()
      }
    }; // 更新商品型号


    const updateGoodsSortsFn = async info => {
      try {
        var newitem = info;
        delete newitem.isEdit;
        delete newitem.createdAt;
        delete newitem.goodsId;
        delete newitem.updatedAt;
        console.log(newitem);
        const resData = await updateGoodsSorts(newitem);

        if (resData && resData.status === 'success') {
          ElMessage({
            message: '保存成功！',
            type: 'success'
          });
          info.isEdit = false;
        }
      } catch (err) {
        console.log(err);
      }
    }; // 保存商品型号


    const submitForm = async item => {
      if (item.sortId) {
        updateGoodsSortsFn(item);
      } else {
        addGoodsSortsFn(item);
      }
    }; // 获取型号列表


    const getGoodsSortsFn = async type => {
      try {
        const resData = await getGoodsSorts({
          goodsId: formModel.goodsId
        });

        if (resData.status === 'success') {
          console.log(resData.data);
          formModel.goodAllSorts = resData.data;

          if (resData.data.length === 0) {
            newGoodsSortsFn();
          }
        } else {
          formModel.goodAllSorts = [];
        }
      } catch (err) {
        console.log(err);
        formModel.goodAllSorts = [];
        newGoodsSortsFn();
      }
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");

      const _component_el_popconfirm = _resolveComponent("el-popconfirm");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_input_number = _resolveComponent("el-input-number");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => dialogVisible.value = $event),
        title: title.value,
        width: "50%",
        "close-on-click-modal": false
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef,
          rules: rules.value
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formModel.goodAllSorts, (item, key) => {
            return _openBlock(), _createElementBlock("div", {
              class: "single-box",
              key: key
            }, [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [item.sortId ? (_openBlock(), _createElementBlock("div", _hoisted_3, "型号ID-" + _toDisplayString(item.sortId), 1)) : _createCommentVNode("", true)]), _createElementVNode("div", _hoisted_4, [_createElementVNode("div", _hoisted_5, [item.isEdit ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              type: "success",
              plain: "",
              icon: _unref(Select),
              onClick: $event => submitForm(item)
            }, {
              default: _withCtx(() => [_createTextVNode(" 保存 ")]),
              _: 2
            }, 1032, ["icon", "onClick"])) : (_openBlock(), _createBlock(_component_el_button, {
              key: 1,
              type: "primary",
              plain: "",
              icon: _unref(Edit),
              onClick: $event => openEditFn(item)
            }, {
              default: _withCtx(() => [_createTextVNode(" 编辑 ")]),
              _: 2
            }, 1032, ["icon", "onClick"]))]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_popconfirm, {
              title: "确定删除吗?",
              onConfirm: $event => deleteGoodsSortsFn(item)
            }, {
              reference: _withCtx(() => [_createVNode(_component_el_button, {
                type: "danger",
                icon: _unref(Delete)
              }, null, 8, ["icon"])]),
              _: 2
            }, 1032, ["onConfirm"])]), key == formModel.goodAllSorts.length - 1 ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createVNode(_component_el_button, {
              type: "primary",
              icon: _unref(Plus),
              onClick: newGoodsSortsFn
            }, null, 8, ["icon"])])) : _createCommentVNode("", true)])]), _createVNode(_component_el_row, null, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_form_item, {
                  label: "型号名称",
                  "label-width": formLabelWidth,
                  prop: "specification"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_input, {
                    modelValue: item.sortName,
                    "onUpdate:modelValue": $event => item.sortName = $event,
                    disabled: !item.isEdit,
                    placeholder: "请输入",
                    autocomplete: "off"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024), _createVNode(_component_el_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createVNode(_component_el_form_item, {
                  label: "型号单价",
                  "label-width": formLabelWidth,
                  placeholder: "请输入",
                  prop: "unitPrice"
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_input_number, {
                    modelValue: item.sortPrice,
                    "onUpdate:modelValue": $event => item.sortPrice = $event,
                    disabled: !item.isEdit,
                    placeholder: "请输入",
                    autocomplete: "off"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024), _createVNode(_component_el_col, {
                span: 8
              }, {
                default: _withCtx(() => [_createVNode(_component_el_form_item, {
                  label: "库存量",
                  "label-width": formLabelWidth
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_input_number, {
                    modelValue: item.sortStocks,
                    "onUpdate:modelValue": $event => item.sortStocks = $event,
                    disabled: !item.isEdit,
                    placeholder: "请输入",
                    autocomplete: "off"
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024), _createVNode(_component_el_row, null, {
              default: _withCtx(() => [_createVNode(_component_el_col, {
                span: 24
              }, {
                default: _withCtx(() => [_createVNode(_component_el_form_item, {
                  label: "型号图",
                  "label-width": formLabelWidth
                }, {
                  default: _withCtx(() => [_createVNode(_unref(ImageUpload), {
                    modelValue: item.sortPhotoUrls,
                    "onUpdate:modelValue": $event => item.sortPhotoUrls = $event,
                    disabled: !item.isEdit,
                    limit: 1
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled"])]),
                  _: 2
                }, 1024)]),
                _: 2
              }, 1024)]),
              _: 2
            }, 1024)]);
          }), 128))]),
          _: 1
        }, 8, ["model", "rules"])]),
        _: 1
      }, 8, ["modelValue", "title"]);
    };
  }

};