import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-09f755e6"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "component-upload-image"
};
const _hoisted_2 = ["src"];
import { reactive, watch } from 'vue';
import { ElMessage } from 'element-plus';
import { delImgItem } from '@/api/goods';
export default {
  __name: 'index',
  props: {
    modelValue: [String, Object, Array],
    // 图片数量限制
    limit: {
      type: Number,
      default: 5
    },
    // 大小限制(MB)
    fileSize: {
      type: Number,
      default: 2
    },
    // 文件类型, 例如['png', 'jpg', 'jpeg']
    fileType: {
      type: Array,
      default: () => ['png', 'jpg', 'jpeg']
    },
    // 是否显示提示
    isShowTip: {
      type: Boolean,
      default: true
    },
    // 校验图片宽高大小是否符合要求
    isCheckWh: {
      type: Boolean,
      default: true
    },
    whlimit: {
      type: Array,
      default: () => [660, 660]
    },
    moreHeight: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uploadImgUrl: {
      type: String,
      default: '/rame/gsfms/uploadGoodsImages'
    }
  },
  emits: ['update:modelValue'],

  setup(__props, {
    emit: __emit
  }) {
    const emits = __emit;
    const props = __props;
    const data = reactive({
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      baseUrl: process.env.VUE_APP_BASE_API,
      // uploadImgUrl: process.env.VUE_APP_BASE_API + '/jf-tech/v1/uploadGoodsImages', // 上传的图片服务器地址
      uploadImgUrl: process.env.VUE_APP_BASE_API + props.uploadImgUrl,
      // 上传的图片服务器地址
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      },
      fileList: [],
      loading: false,
      noneBtnImg: false
    }); // 对象转成指定字符串分隔

    const listToString = (list, separator) => {
      let strs = '';
      separator = separator || ',';

      for (const i in list) {
        // strs += list[i].url.replace(data.baseUrl, '') + separator
        strs += list[i].url + separator;
      }

      return strs !== '' ? strs.substr(0, strs.length - 1) : '';
    };

    watch(() => props.modelValue, async val => {
      if (val) {
        // 首先将值转为数组
        // 将当前fileList取出转化为字符串
        if (data.fileList.length > 0) {
          const curStr = await listToString(data.fileList);

          if (val === curStr) {
            return;
          }
        }

        const list = Array.isArray(val) ? val : props.modelValue.split(','); // 然后将数组转为对象数组

        data.fileList = list.map(item => {
          return {
            name: item,
            url: item
          };
        });
      } else {
        data.noneBtnImg = false;
        data.fileList = [];
        return [];
      }
    }, {
      immediate: true,
      deep: true
    });

    const handleImgChange = (file, fileList) => {
      data.noneBtnImg = fileList.length >= props.limit;
    };

    const delImgItemFn = imgArr => {
      delImgItem({
        images: [].concat(imgArr)
      }).then(res => {
        if (res.status === 200) {
          ElMessage.success('删除成功');
        }
      });
    }; // 删除图片


    const handleRemove = (file, fileList) => {
      console.log('删除图片');
      console.log(file.url);
      var imgName = '';

      if (file.url) {
        imgName = file.url.split('?image=')[1];
      } else {
        imgName = file.response.url.split('?image=')[1];
      } // console.log(imgName)


      delImgItemFn([imgName]);
      const findex = data.fileList.findIndex(f => f.name.includes(imgName));

      if (findex > -1) {
        data.fileList.splice(findex, 1);
      }

      emits('update:modelValue', listToString(data.fileList));
    }; // // 上传成功回调


    const handleUploadSuccess = res => {
      console.log(res);
      data.fileList.push({
        name: res.data,
        url: res.data
      });
      console.log(data.fileList);
      console.log('上传图片组件'); // setTimeout(() => {

      emits('update:modelValue', listToString(data.fileList)); // emits('getfileList', listToString(data.fileList))
      // data.loading = false
      // }, 1000)
      // data.loading.close()
    }; // // 上传前loading加载
    // const handleBeforeUpload = async (file) => {
    //   let isImg = false
    //   if (props.fileType.length) {
    //     let fileExtension = ''
    //     if (file.name.lastIndexOf('.') > -1) {
    //       fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
    //     }
    //     isImg = props.fileType.some((type) => {
    //       if (file.type.indexOf(type) > -1) return true
    //       if (fileExtension && fileExtension.indexOf(type) > -1) return true
    //       return false
    //     })
    //   } else {
    //     isImg = file.type.indexOf('image') > -1
    //   }
    //   if (!isImg) {
    //     ElMessage.error(
    //       `文件格式不正确, 请上传${props.fileType.join('/')}图片格式文件!`
    //     )
    //     return false
    //   }
    //   if (props.fileSize) {
    //     const isLt = file.size / 1024 / 1024 < props.fileSize
    //     if (!isLt) {
    //       ElMessage.error(`上传头像图片大小不能超过 ${props.fileSize} MB!`)
    //       return false
    //     }
    //   }
    //   const isSize = await new Promise(function (resolve, reject) {
    //     const width = props.whlimit[0]
    //     const height = props.whlimit[1]
    //     const _URL = window.URL || window.webkitURL
    //     const image = new Image()
    //     image.onload = function () {
    //       const valid =
    //         image.width === width &&
    //         image.height === height &&
    //         image.width / image.height === 1
    //       // 区间范围校验
    //       const moreHeightValid = props.moreHeight
    //         ? image.width === width && image.height >= 300 && image.height <= 1000
    //         : false
    //       valid || moreHeightValid
    //         ? resolve()
    //         : reject(new Error('图片尺寸不符合要求'))
    //     }
    //     image.src = _URL.createObjectURL(file)
    //   }).then(
    //     () => {
    //       return file
    //     },
    //     () => {
    //       ElMessage.error('上传的图片尺寸不符合要求,请检查后重新上传')
    //       return Promise.reject(new Error('图片尺寸不符合要求'))
    //     }
    //   )
    //   // 校验图片宽高大小是否符合要求
    //   if (props.isCheckWh) {
    //     return isSize
    //   }
    //   // data.loading = ElMessage({
    //   //   lock: true,
    //   //   text: '上传中',
    //   //   background: 'rgba(0, 0, 0, 0.7)'
    //   // })
    // }
    // // 文件个数超出


    const handleExceed = () => {
      ElMessage.error(`上传文件数量不能超过 ${props.limit} 个!`);
    }; // // 上传失败


    const handleUploadError = () => {
      ElMessage({
        type: 'error',
        message: '上传失败'
      }); // data.loading.close()
    }; // 预览


    const handlePictureCardPreview = file => {
      data.dialogImageUrl = file.url;
      data.dialogVisible = true;
    };

    return (_ctx, _cache) => {
      const _component_Plus = _resolveComponent("Plus");

      const _component_el_icon = _resolveComponent("el-icon");

      const _component_el_upload = _resolveComponent("el-upload");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_upload, {
        action: data.uploadImgUrl,
        "list-type": "picture-card",
        name: "goodsImages",
        limit: __props.limit,
        "on-remove": handleRemove,
        "on-error": handleUploadError,
        "on-exceed": handleExceed,
        headers: data.headers,
        multiple: true,
        disabled: __props.disabled,
        "on-change": handleImgChange,
        "file-list": data.fileList,
        "on-success": handleUploadSuccess,
        "on-preview": handlePictureCardPreview
      }, {
        default: _withCtx(() => [_createVNode(_component_el_icon, null, {
          default: _withCtx(() => [_createVNode(_component_Plus)]),
          _: 1
        })]),
        _: 1
      }, 8, ["action", "limit", "headers", "disabled", "file-list"]), _createVNode(_component_el_dialog, {
        modelValue: data.dialogVisible,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => data.dialogVisible = $event),
        title: "预览",
        width: "800",
        "append-to-body": ""
      }, {
        default: _withCtx(() => [_createElementVNode("img", {
          src: data.dialogImageUrl,
          style: {
            "display": "block",
            "max-width": "100%",
            "margin": "0 auto"
          }
        }, null, 8, _hoisted_2)]),
        _: 1
      }, 8, ["modelValue"])]);
    };
  }

};