import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "wrap"
};
const _hoisted_2 = {
  class: "left"
};
const _hoisted_3 = {
  style: {
    "display": "flex",
    "align-items": "center"
  }
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "dialog-footer"
};
import { reactive, ref, computed } from 'vue'; // import { AllDict } from '@/utils/common'

import { editAfterSales } from '@/api/afterSales';
import { ElMessage } from 'element-plus';
const formLabelWidth = '100px';
export default {
  __name: 'modal-info',
  emits: ['refresh'],

  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const dialogVisible = ref(false);
    const emit = __emit;
    const formRef = ref();
    const formModel = reactive({
      afterSaleId: 9,
      trade_uuid: null,
      userId: null,
      reason: null,
      text: null,
      photoUrls: null,
      returnTrackingPhone: null,
      returnTrackingNumber: null,
      node: null,
      state: null,
      stateInfo: null,
      orderStatus: null
    });

    const showModal = data => {
      Object.assign(formModel, data);
      dialogVisible.value = true;
    };

    const hideModal = () => {
      dialogVisible.value = false;
    };

    const title = computed(() => {
      return `订单ID: ${formModel.afterSaleId}`;
    });

    const editOrderFn = async () => {
      const resData = await editAfterSales(formModel);

      if (resData && resData.status === 'success') {
        ElMessage({
          message: '编辑成功！',
          type: 'success'
        });
        emit('refresh');
        hideModal();
      }
    };

    const submitForm = async formEl => {
      await formRef.value.validate((valid, fields) => {
        if (valid) {
          console.log('submit!');
          editOrderFn();
        } else {
          console.log('error submit!', fields);
        }
      });
    };

    const changeState = val => {
      if (val) {
        const msgDict = ['已收到您的售后申请，平台将在24小时内给予答复。', '已同意您的售后申请，请尽早退货并填写寄回单号。', '请重新填写申请原因。', '极峰尚未收到寄回快递，请耐心等待。', '极峰已收到快递，正在处理中……', '已经按原路退回，退款金额将在1~7天内到账。', '请在【我的订单】的待收货里查看换货信息。', '客服将在24小时内与您联系。'];
        formModel.stateInfo = msgDict[val - 1] || '未定义回复语料';
      } else {
        formModel.stateInfo = '';
      }
    };

    __expose({
      showModal
    });

    return (_ctx, _cache) => {
      const _component_el_card = _resolveComponent("el-card");

      const _component_el_divider = _resolveComponent("el-divider");

      const _component_el_input = _resolveComponent("el-input");

      const _component_el_form_item = _resolveComponent("el-form-item");

      const _component_el_image = _resolveComponent("el-image");

      const _component_el_col = _resolveComponent("el-col");

      const _component_el_row = _resolveComponent("el-row");

      const _component_el_option = _resolveComponent("el-option");

      const _component_el_select = _resolveComponent("el-select");

      const _component_el_form = _resolveComponent("el-form");

      const _component_el_button = _resolveComponent("el-button");

      const _component_el_dialog = _resolveComponent("el-dialog");

      return _openBlock(), _createBlock(_component_el_dialog, {
        modelValue: dialogVisible.value,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => dialogVisible.value = $event),
        title: "编辑订单信息",
        "close-on-click-modal": false
      }, {
        footer: _withCtx(() => [_createElementVNode("span", _hoisted_6, [_createVNode(_component_el_button, {
          onClick: _cache[9] || (_cache[9] = $event => dialogVisible.value = false)
        }, {
          default: _withCtx(() => [_createTextVNode("取消")]),
          _: 1
        }), _createVNode(_component_el_button, {
          type: "primary",
          onClick: submitForm
        }, {
          default: _withCtx(() => [_createTextVNode("保存")]),
          _: 1
        })])]),
        default: _withCtx(() => [_createVNode(_component_el_card, {
          class: "box-card mb-20"
        }, {
          default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("span", null, _toDisplayString(title.value), 1)])])]),
          _: 1
        }), _createVNode(_component_el_form, {
          model: formModel,
          ref_key: "formRef",
          ref: formRef
        }, {
          default: _withCtx(() => [_createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("申诉信息")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 24
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "订单编号",
                prop: "trade_uuid",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.trade_uuid,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => formModel.trade_uuid = $event),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "用户ID",
                prop: "userId",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "input",
                  modelValue: formModel.userId,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => formModel.userId = $event),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "申诉理由",
                prop: "reason",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.reason,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => formModel.reason = $event),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "申诉补充",
                prop: "reason",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  type: "textarea",
                  modelValue: formModel.text,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => formModel.text = $event),
                  autocomplete: "off",
                  disabled: ""
                }, null, 8, ["modelValue"])]),
                _: 1
              }), _createVNode(_component_el_form_item, {
                label: "申诉图片",
                prop: "reason",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createElementVNode("div", _hoisted_3, [!formModel.photoUrls ? (_openBlock(), _createElementBlock("span", _hoisted_4, "-")) : (_openBlock(), _createElementBlock("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formModel.photoUrls.split(','), (item, i) => {
                  return _openBlock(), _createBlock(_component_el_image, {
                    key: i,
                    style: {
                      "width": "100px",
                      "height": "100px"
                    },
                    src: item,
                    "preview-src-list": formModel.photoUrls ? formModel.photoUrls.split(',') : [],
                    fit: "cover",
                    "preview-teleported": true
                  }, null, 8, ["src", "preview-src-list"]);
                }), 128))]))])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("寄回信息")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "寄回单号",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.returnTrackingNumber,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => formModel.returnTrackingNumber = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "寄件人电话",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_input, {
                  modelValue: formModel.returnTrackingPhone,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => formModel.returnTrackingPhone = $event),
                  autocomplete: "off"
                }, null, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_divider, {
            "content-position": "left"
          }, {
            default: _withCtx(() => [_createTextVNode("售后操作")]),
            _: 1
          }), _createVNode(_component_el_row, null, {
            default: _withCtx(() => [_createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "售后节点",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.node,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => formModel.node = $event),
                  placeholder: "请选择",
                  clearable: ""
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "提交申请",
                    value: 1
                  }), _createVNode(_component_el_option, {
                    label: "客服审核",
                    value: 2
                  }), _createVNode(_component_el_option, {
                    label: "极峰收货",
                    value: 3
                  }), _createVNode(_component_el_option, {
                    label: "完成",
                    value: 4
                  })]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            }), _createVNode(_component_el_col, {
              span: 12
            }, {
              default: _withCtx(() => [_createVNode(_component_el_form_item, {
                label: "审核状态",
                "label-width": formLabelWidth
              }, {
                default: _withCtx(() => [_createVNode(_component_el_select, {
                  modelValue: formModel.state,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => formModel.state = $event),
                  placeholder: "请选择",
                  clearable: "",
                  onChange: changeState
                }, {
                  default: _withCtx(() => [_createVNode(_component_el_option, {
                    label: "等待审核结果",
                    value: 1
                  }), _createVNode(_component_el_option, {
                    label: "审核通过",
                    value: 2
                  }), _createVNode(_component_el_option, {
                    label: "审核未通过",
                    value: 3
                  }), _createVNode(_component_el_option, {
                    label: "快递运输中",
                    value: 4
                  }), _createVNode(_component_el_option, {
                    label: "取件成功",
                    value: 5
                  }), _createVNode(_component_el_option, {
                    label: "已退款",
                    value: 6
                  }), _createVNode(_component_el_option, {
                    label: "已换货",
                    value: 7
                  }), _createVNode(_component_el_option, {
                    label: "售后失败",
                    value: 8
                  })]),
                  _: 1
                }, 8, ["modelValue"])]),
                _: 1
              })]),
              _: 1
            })]),
            _: 1
          }), _createVNode(_component_el_form_item, {
            label: "售后审核信息",
            "label-width": formLabelWidth
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              modelValue: formModel.stateInfo,
              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => formModel.stateInfo = $event),
              autosize: "",
              type: "textarea",
              autocomplete: "off"
            }, null, 8, ["modelValue"])]),
            _: 1
          })]),
          _: 1
        }, 8, ["model"])]),
        _: 1
      }, 8, ["modelValue"]);
    };
  }

};